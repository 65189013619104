<template>
  <div class="checkout-order-details">
    <section>
      <div class="container is-fullhd">
        <h3 class="title is-3 has-text-centered pb-6">Tramitar pedido</h3>
        <div class="checkout-wrap">
          <tab-wrap></tab-wrap>
          <div class="columns content-checkout-wrap is-flex-wrap-wrap">
            <div class="column is-12 ">
              <h4 class="title is-size-4">Dirección de facturación</h4>
            </div>
            <div class="column is-12 mb-6">
              <div class="columns is-variable is-8">
                <!-- LEFT COLUMN -->
                <div class="column is-8 left-column">

                  <div class="columns control destination-wrap">
                    <div class="column is-12">
                      <div class="field">
                        <label class="checkbox">
                          <input type="checkbox" v-model="billingHasSameAddress">
                          La dirección de facturación es la misma que la dirección de envío
                        </label>
                      </div>
                    </div>
                  </div>

                  <hr v-if="!billingHasSameAddress">
                  <div class="columns control destination-wrap" v-if="!billingHasSameAddress">
                    <template v-for="location in locations">
                      <div class="column is-4" v-bind:key="location.id">
                        <label class="radio">
                          <input type="radio" name="pickup-address" :value="location.id" v-model="billing_location_id">
                          <span class="has-text-weight-bold ml-3">{{location.address.name}}</span>
                          <p>
                            <!-- <strong>{{location.address.first_name}} {{location.address.last_name}}</strong><br> -->
                            {{location.address.address1}}<br>
                            {{location.address.zip}} {{location.address.city}} ({{location.address.province}}), {{location.address.country}}<br>
                            {{location.address.phone}}<br><br>
                            <a class="is-underlined" href="#" @click.prevent="editBillingLocation(location)">Editar</a>
                          </p>
                        </label>
                      </div>
                    </template>
                  </div>
                  <div class="columns mt-4 is-multiline" v-if="!billingHasSameAddress">
                    <div class="column is-12">
                      <div class="field">
                        <label class="checkbox">
                          <input type="checkbox" v-model="isAddBillingAddressOpen">
                          Añadir una nueva dirección
                        </label>
                      </div>
                    </div>
                    <edit-address v-if="isAddBillingAddressOpen || isEditModeBilling" :is-edit-mode="isEditModeBilling" :location-to-edit="clinic_location_billing" :callback="closeEditBilling"></edit-address>
                  </div>

                  <div class="columns mt-4 is-multiline has-border-bottom">
                    <div class="column is-12 ">
                      <h4 class="title is-size-4">Seleccionar</h4>
                    </div>
                    <div class="column is-12 ">
                      <label class="radio" for="payment-method-sepa">
                        <input id="payment-method-sepa" type="radio" class="stlab-radio" name="payment-method-sepa" value="sepa" v-model="paymentMethod">
                        <span class="has-text-weight-bold ml-3">Transferencia SEPA</span>
                      </label>
                      <br>
                      <label class="radio" for="payment-method-paypal">
                        <input id="payment-method-paypal" type="radio" class="stlab-radio" name="payment-method-paypal" v-model="paymentMethod" value="paypal">
                        <span class="has-text-weight-bold ml-3">PayPal</span>
                      </label>
                      <br>
                      <label class="radio" for="payment-method-credit-card">
                        <input id="payment-method-credit-card" type="radio" class="stlab-radio" name="payment-method-credit-card" v-model="paymentMethod" value="credit-card">
                        <span class="has-text-weight-bold ml-3">Tarjeta de crédito</span>
                      </label>
                    </div>
                  </div>

                  <div class="columns control credit-cards-wrap mt-5" v-if="paymentMethod === 'credit-card'">
                    <div class="column is-4">
                      <label class="radio" for="foobar1">
                        <input type="radio" name="foobar1" checked>
                        <span class="has-text-weight-bold ml-3">Mastercard</span>
                        <p>
                          Termina con 4899<br>
                          Cad. 9/24<br>
                          John Doe<br>
                          <a class="is-underlined" href="#">Editar</a>
                        </p>
                      </label>
                    </div>
                    <div class="column is-4">
                      <label class="radio" for="foobar2">
                        <input type="radio" name="foobar2">
                        <span class="has-text-weight-bold ml-3">Visa</span>
                        <p>
                          Termina con 4899<br>
                          Cad. 9/24<br>
                          John Doe<br>
                          <a class="is-underlined" href="#">Editar</a>
                        </p>
                      </label>
                    </div>
                  </div>

                  <div class="field" v-if="paymentMethod === 'credit-card'">
                    <label class="checkbox">
                      <input type="checkbox" v-model="newCreditCard">
                      Añadir una nueva tarjeta
                    </label>
                  </div>

                  <div v-if="newCreditCard" class="column is-12">
                      <div class="columns mt-4 is-multiline">
                        <div class="column is-6 mb-3">
                          <div class="select is-primary is-full-width">
                            <select>
                              <option>Tipo de tarjeta*</option>
                              <option>With options</option>
                            </select>
                          </div>
                        </div>
                        <div class="column is-6 mb-3">
                          <div class="field">
                            <input-text :placeholder="'Número de tarjeta*'" v-model="card_number" :name="'card_number'"></input-text>
                          </div>
                        </div>

                        <div class="column is-6 mb-3">
                          <div class="field">
                            <input-text :placeholder="'Nombre y Apellido*'" v-model="card_owner" :name="'card_owner'"></input-text>
                          </div>
                        </div>
                        <div class="column is-6 mb-3">
                          <div class="select is-primary is-full-width">
                            <select>
                              <option>Fecha de caducidad*</option>
                              <option>With options</option>
                            </select>
                          </div>
                        </div>

                        <div class="column is-6 mb-3">
                          <div class="field">
                            <input-text :placeholder="'Código de seguridad (CVV)*'" v-model="cvv" :name="'cvv'"></input-text>
                          </div>
                        </div>

                        <div class="column is-6 mb-3">
                          <div class="field">
                            <label class="checkbox">
                              <input type="checkbox">
                              Guardar la tarjeta para compras futuras
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                  <hr>

                  <!-- <div class="field">
                    <label class="checkbox">
                      <input type="checkbox" v-model="privacyPolicy">
                      He leído y acepto la Política de Privacidad*
                    </label>
                  </div>

                  <div class="field">
                    <label class="checkbox">
                      <input type="checkbox" v-model="termsAndConditions">
                      He leído y acepto los términos y condiciones*
                    </label>
                  </div>

                  <div class="field">
                    <label class="checkbox">
                      <input type="checkbox" v-model="marketing">
                      Doy mi consentimiento para el uso de mis datos con fines de marketing y para recibir ofertas y promociones.
                    </label>
                  </div> -->

                  <div class="column is-12 mt-5">
                    <button class="button is-primary is-fullwidth is-medium mt-4" @click="finalizeStep('/checkout/order-completed')">CONFIRMA EL PAGO ({{shoppingCart.total_price / 100}}€) Y COMPLETA EL PEDIDO</button>
                  </div>

                </div>

                <!-- RIGTH COLUMN -->
                <order-recap-sidebar></order-recap-sidebar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import TabWrap from '@/components/checkout/TabWrap.vue'
import OrderRecapSidebar from '@/components/checkout/OrderRecapSidebar.vue'
import InputText from '@/components/FormElements/InputText.vue'
import EditAddress from '@/components/Clinic/EditAddress.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Payment',
  components: {
    Footer,
    TabWrap,
    OrderRecapSidebar,
    InputText,
    EditAddress
  },
  computed: {
    ...mapGetters({
      clinic: 'user/clinic',
      locations: 'clinic/locations',
      shoppingCart: 'clinic/shoppingCart',
      currentOrder: 'clinic/currentOrder'
    })
  },
  data () {
    return {
      stripeInstance: null,
      //
      destinations: [],
      shippingMethods: [],
      showAddNewDestination: false,
      requireInvoice: false,
      newCreditCard: false,
      // FORM
      card_number: '',
      card_owner: '',
      cvv: '',
      privacyPolicy: true,
      termsAndConditions: true,
      marketing: false,
      // Address
      billingHasSameAddress: true,
      billing_location_id: null,
      clinic_location_billing: null,
      isAddBillingAddressOpen: false,
      isEditModeBilling: false,
      paymentMethod: 'sepa'
    }
  },
  methods: {
    finalizeStep (path) {
      if (!this.privacyPolicy || !this.termsAndConditions) {
        const errorMessage = '¡Error! Privacy policy and terms and conditions must be accepted.'
        this.$store.commit({
          type: 'application/ADD_FEEDBACK',
          feedback: {
            type: 'error',
            message: errorMessage
          }
        })
        return
      }

      if (this.currentOrder.shipping_location_id === null || this.currentOrder.pickup_location_id === null) {
        const errorMessage = '¡Error! Shipments locations are missing'
        this.$store.commit({
          type: 'application/ADD_FEEDBACK',
          feedback: {
            type: 'error',
            message: errorMessage
          }
        })
        this.goTo('/checkout/shipping')
      } else {
        this.$store.commit({
          type: 'clinic/SET_CURRENT_ORDER_SHIPMENTS',
          currentOrder: {
            shipping_location_id: this.currentOrder.shipping_location_id,
            billing_location_id: this.billing_location_id,
            pickup_location_id: this.currentOrder.pickup_location_id
          }
        })

        const self = this
        this.$store.dispatch('clinic/createOrder', {
          clinicId: this.clinic.id,
          data: this.currentOrder,
          cb: (data) => {
            console.log(data)
            self.$store.commit({
              type: 'application/ADD_FEEDBACK',
              feedback: {
                type: 'success',
                message: 'Order created successfully!'
              }
            })
            let ordersId = ''
            for (let i = 0; i < data.length; i++) {
              if (i === data.length - 1) {
                ordersId += data[i].id
              } else {
                ordersId += data[i].id + '|'
              }
            }
            // self.goTo(path + '/' + ordersId)
            self.goTo('/clinic/orders/' + ordersId + '/completed')
          },
          cbError: () => {
            const errorMessage = '¡Error! Can\'t create the order'
            self.$store.commit({
              type: 'application/ADD_FEEDBACK',
              feedback: {
                type: 'error',
                message: errorMessage
              }
            })
          }
        })
      }
    },
    goTo (path) {
      this.$router.push({ path: path })
    },
    initStripeElement () {
      const self = this
      this.$nextTick(() => {
        const options = {
          clientSecret: self.setupIntent.client_secret
          // Fully customizable with appearance API.
          // appearance: {/*...*/},
        }

        // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
        const elements = self.stripeInstance.elements(options)

        // Create and mount the Payment Element
        const paymentElement = elements.create('payment')
        paymentElement.mount('#payment-element')

        self.initStripeForm(elements)
      })
    },
    initStripeForm (elements) {
      const self = this
      const form = document.getElementById('payment-form')
      this.isFormInitialized = true
      form.addEventListener('submit', async (event) => {
        self.$vs.loading()
        event.preventDefault()

        const { error } = await self.stripeInstance.confirmSetup({
          // `Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
            return_url: window.location.origin + window.location.pathname
          }
        })

        if (error) {
          // This point will only be reached if there is an immediate error when
          // confirming the payment. Show error to your customer (for example, payment
          // details incomplete)
          // const messageContainer = document.querySelector('#error-message')
          // messageContainer.textContent = error.message
          self.$vs.loading.close()
          self.showError(error.message)
        } else {
          self.$vs.loading.close()
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
        }
      })
    },
    editBillingLocation (location) {
      this.isEditModeBilling = true
      this.clinic_location_billing = location
    },
    closeEditBilling () {
      this.isEditModeBilling = false
      this.isAddBillingAddressOpen = false
      this.clinic_location_billing = null
    },
    setDefaultAddress () {
      for (let i = 0; i < this.locations.length; i++) {
        if (this.locations[i].primary) {
          this.billing_location_id = this.locations[i].id
        }
      }
    },
    fetchLocations () {
      const self = this
      this.$store.dispatch('clinic/getLocations', {
        clinicId: this.clinic.id,
        cb: (data) => {
          console.log(data)
          if (self.currentOrder.shipping_location_id === null) {
            self.setDefaultAddress()
            self.billingHasSameAddress = false
          } else {
            self.billing_location_id = self.currentOrder.shipping_location_id
          }
        },
        cbError: () => {
          const errorMessage = '¡Error! Can\'t get locations'
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: errorMessage
            }
          })
        }
      })
    }
  },
  created () {
    this.fetchLocations()
    this.stripeInstance = Stripe(process.env.VUE_APP_STRIPE_PK, {
      stripeAccount: process.env.VUE_APP_STRIPE_ACCOUNT_ID
    })
    // console.log(process.env.VUE_APP_STRIPE_PK)
  }
}
</script>

<style scoped lang="scss">
.checkout-order-details{
  width: 100%;
  section{
    width: 100%;
    padding: 60px 0px;
    .checkout-wrap{
      width: 100%;
      border-radius: 4px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
      .content-checkout-wrap{
        padding: 60px;
        .left-column{
          .destination-wrap{}
          .has-border-bottom{
            border-bottom: 1px solid #57567C;
          }
        }
      }
    }
  }
}
</style>
